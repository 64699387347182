<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="academic_year" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="formData.academic_year" :validate-error="errors[0]"
                                v-if="!disabled" />
                            <div class="label-as-input" v-else>{{ isNotNullValue(formData.academic_year) }}</div>

                        </b-form-group>
                    </Validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="name" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('name')">
                            <b-form-input :disabled="disabled" v-model="formData.name" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>

                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="nationality_code" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('nationality')">
                            <b-form-select :disabled="disabled" v-model="formData.nationality_type"
                                :class="errors[0] ? 'is-invalid' : ''"
                                :options="[{ text: $t('turkey'), value: 'TR' }, { text: $t('abroad'), value: 'YU' }]"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>

                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="gender" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox :disabled="disabled" :validate-error="errors[0]" v-model="formData.gender">
                            </gender-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="quota" rules="required|numeric" v-slot="{ errors }">
                        <b-form-group :label="$t('quota')">
                            <b-form-input :disabled="disabled" v-model="formData.quota" type="number"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>

                <b-col cols="12" md="6" lg="4">
                    <Validation-provider name="remaining_quota" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('remaining_quota')">
                            <b-form-input :disabled="true" v-model="formData.remaining_quota" type="number"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>

            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button class="px-5" :variant="formType == 'delete' ? 'danger' : 'primary'" @click="createForm"
                    :disabled="loading">
                    {{ buttonText }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>

// Services
import UnihallQuotaService from '@/services/UnihallQuotaService';

export default {
    name: "UnihallQuotasDataForm",
    props: {
        formType: {
            type: String,
            required: true
        },
        formId: {
            type: Number,
            default: -1
        },
        value: {
            type: Object,
            default: () => { }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    watch: {
        formType: {
            handler() {
                this.$refs.formModalValidate.reset();
            },
        }
    },
    computed: {
        formData: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit("input", val)
                return val
            }
        },
        buttonText() {
            if (this.formType === "show") {
                return this.$t('close')
            } else if (this.formType === "add") {
                return this.$t('save')
            } else if (this.formType === "update") {
                return this.$t('update')
            } else if (this.formType === "delete") {
                return this.$t("delete");
            }
            return ''
        }
    },
    methods: {
        async createForm() {
            if (this.formType === "show") {
                this.$emit('close')
                return
            }
       
            const valid = await this.$refs.formModalValidate.validate()
            if (!valid && this.formType !== "delete") {
                return
            }
            this.loading = true

            let response = null
            let result = null

            switch (this.formType) {
                case "add":
                    response = await UnihallQuotaService.store(this.formData).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                        .finally(() => {
                            this.loading = false
                        })
                    break;

                case "update":
                    response = await UnihallQuotaService.update(this.formId, this.formData).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                        .finally(() => {
                            this.loading = false
                        })

                    break;

                case "delete":
                    result = await this.$swal.fire({
                        text: this.$t("are_you_sure_to_delete"),
                        showCancelButton: true,
                        confirmButtonText: this.$t("yes"),
                        cancelButtonText: this.$t("no"),
                    });
                    if (result.isConfirmed) {
                        response = await UnihallQuotaService.del(
                            this.formId
                        )
                            .catch((e) => {
                                this.showErrors(
                                    e,
                                    this.$refs.formModalValidate
                                );
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        return
                    }
                    break;

                default:
                    break;
            }

            if (!response) {
                this.$toast.error(this.$t('something_went_wrong'));
                return
            }


            if (response?.data?.success) {
                this.formClear()
                this.$refs.formModalValidate.reset();
                if (this.formType === "update" || this.formType === "delete") {
                    this.$emit('close')
                }
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        formClear() {
            this.formData = {}
        }
    },
}
</script>
<style lang="">
    
</style>